<template>
  <div class="wechat-container">
    <div class="pay-fail" v-if="payStatus === 'fail'">
      <div class="result flex align-center fail">
        <van-icon class="pay-icon" name="clear"></van-icon>
        <span>亲，支付失败啦</span>
      </div>
      <van-button
        type="danger"
        class="repay"
        size="large"
        @click="pullPay(payData)"
        >重新支付</van-button
      >
      <van-button size="large" @click="$router.go(-1)">取消支付</van-button>
    </div>

    <div class="pay-success" v-if="payStatus === 'success'">
      <div class="result flex flex-column align-center success">
        <van-icon class="pay-icon" name="checked"></van-icon>
        <span>恭喜您，支付成功</span>
      </div>
      <van-button type="primary" size="large" :to="{ name: 'OrderList' }"
        >订单查询</van-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { mapActions } from 'vuex'
let wechat
if (typeof window !== 'undefined') {
  wechat = require('weixin-js-sdk')
}
import { Button } from 'vant'

export default {
  name: 'PayResult',
  components: {
    'van-button': Button,
  },
  data() {
    return {
      payStatus: 'payying',
      payData: {},
    }
  },
  mounted() {
    const code = this.$route.query.code,
      orderSn = this.$route.query.state,
      url = window.location.href
    if (!code || !orderSn) {
      this.$router.go(-1) // 如果code不存在，直接返回
      return
    }
    let loading = Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    this.getWechatPayParams({ orderSn, url, type: 'JSAPI', code })
      .then(({ data }) => {
        const wxJsConfig = data.wxJsConfig
        const payData = data.payData
        this.payData = payData
        wechat.config(wxJsConfig)
        wechat.ready(() => {
          loading.clear()
          this.pullPay(payData)
        })
        wechat.error((err) => {
          console.log(err)
          loading.clear()
          this.payStatus = 'fail'
        })
      })
      .catch((err) => {
        // 跳转到支付失败
        loading.clear()
        this.$dialog
          .confirm({
            title: '支付通知',
            message: err.msg || '获取支付参数失败，请返回重试',
          })
          .then(() => {
            this.$router.go(-1)
          })
          .catch(() => {
            this.$router.go(-1)
          })
      })
  },
  methods: {
    ...mapActions('wechat', ['getWechatPayParams']),
    pullPay(data) {
      wechat.chooseWXPay({
        timestamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.package,
        signType: data.signType,
        paySign: data.paySign,
        success: () => {
          this.payStatus = 'success'
        },
        cancel: () => {
          this.payStatus = 'fail'
        },
        fail: () => {
          this.payStatus = 'fail'
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.wechat-container {
  padding: 0 20px; /*no*/
}
.result {
  padding: 20px 0;

  flex-direction: column;
  .pay-icon {
    font-size: 100px;
    margin-bottom: 25px;
  }

  &.fail {
    color: red;
  }

  &.success {
    color: #07c160;
  }
}

.pay-fail {
  .repay {
    margin-bottom: 10px;
  }
}
</style>
